import {calculaCostes} from "./simuladores";
import touchHandler from './touchHandler';

$(document).ready(function(){

    function filtrarPlanes() {
        let prodClassSel = "."+$('#costePlanes').val();
        let seguroClassSel = "."+$('#opcionSeguro').val();
        $('.btn.btn-simulador').hide(); // oculto todos
        $(prodClassSel+seguroClassSel).filter(function() {
            return window.default_euros_slider >= $(this).attr("data-mincantidad") && window.default_euros_slider <= $(this).attr("data-maxcantidad") ;
        }).show();  // muestro solo los que cumplan las condiciones

        // si el plan seleccionado está oculto mostrar la opción más cercana
        if ($('.btn.btn-simulador.active').is(":hidden")) $('.btn.btn-simulador.active').next().click();
    }


    // mostrar select options de producto solo si existen
    if (window.configsTabla.some((plan) => plan.label === "CMIXTO")) $('#costePlanes option[value="cmixto"]').show();
    if (window.configsTabla.some((plan) => plan.label === "CSIN")) $('#costePlanes option[value="csin"]').show();
    if (window.configsTabla.some((plan) => plan.label === "C5" || plan.label === "C10" || plan.label === "C20")) $('#costePlanes option[value="cbasico"]').show();
    if (window.configsTabla.some((plan) => plan.label === "CCLASICO")) $('#costePlanes option[value="cclasico"]').show();

    // mostrar select options de seguros solo si existen
    if (window.configsTabla.some((plan) => plan.insuranceOption === "Básico")) $('#opcionSeguro option[value="insbasico"]').show();
    if (window.configsTabla.some((plan) => plan.insuranceOption === "Plus")) $('#opcionSeguro option[value="insplus"]').show();
    if (window.configsTabla.some((plan) => plan.insuranceOption === "Único")) $('#opcionSeguro option[value="insunico"]').show();

    filtrarPlanes();


    // calculamos coste con valores iniciales
    calculaCostes(window.default_euros_slider, window.default_months_slider, window.default_mode, window.default_carencia);

    // event listeners

    var inputEurosShow = $("#input_euros_show");
    var inputDownpaymentShow = $("#input_downpayment_show");


    inputEurosShow.click( function (event){
        inputEurosShow.val(window.slider_euros.slider( "value").toFixed(2).replace('.',','));
        inputEurosShow.select();
    });

    inputEurosShow.change( function (event){
        if(isNaN(inputEurosShow.val().replace(',','.'))) {
            inputEurosShow.val(window.default_euros_slider);
        }
        window.slider_euros.slider( "value", inputEurosShow.val().replace(',','.'));
        window.default_euros_slider = window.slider_euros.slider("value");
        filtrarPlanes();
        calculaCostes (window.default_euros_slider, window.default_months_slider, window.default_mode, window.default_carencia);
        inputEurosShow.blur();
    });

    inputEurosShow.blur( function (event){
        inputEurosShow.val(window.slider_euros.slider("value").toFixed(2).replace('.',',')+" €");
    });

    inputDownpaymentShow.click( function (event){
        inputDownpaymentShow.select();
    });

    inputDownpaymentShow.on('keyup change', function (event){
        if(isNaN(inputDownpaymentShow.val().replace(',','.'))) {
            inputDownpaymentShow.val(0);
        } else {
            if (inputDownpaymentShow.val() > (window.default_euros_slider-300)) {
                inputDownpaymentShow.val(window.default_euros_slider-300);
            }
            if (inputDownpaymentShow.val() < 0) {
                inputDownpaymentShow.val(0);
            }
        }
        window.downpayment = inputDownpaymentShow.val().replace(',','.');
        calculaCostes (window.default_euros_slider, window.default_months_slider, window.default_mode, window.default_carencia);
    });

    //evento para cuando el usuario hace click en otro radio button

    $('.btn.btn-simulador').click( function(event) {
        (window.cantidadFija === 0) ? window.default_euros_slider = window.slider_euros.slider("value") : window.default_euros_slider;

        var idPlanBuscada=$(this).find('input:first').attr("data-idplan");
        window.default_months_slider = window.configsTabla[0].meses;
        window.indiceMeses = 0;
        window.default_months_slider = window.configsTabla[idPlanBuscada].meses;
        window.indiceMeses = idPlanBuscada;
        calculaCostes (window.default_euros_slider, window.default_months_slider, window.default_mode, window.default_carencia);

    });

    // muestra información de cobros cuando toca

    if (window.associatedInvestor != null) {
        $('.btn.btn-default.btn-modo.afiliado').click( function(event) {
            window.default_mode = $(this).find('input:first').attr("data-mode");
            if ($(this).find('input:first').attr("data-mode") === "cobro"){
                $('#filaIngresos').show();
                $('#filafinancedRate').hide();
            } else {
                $('#filaIngresos').hide();
                $('#filafinancedRate').show();
            }
            calculaCostes (window.default_euros_slider, window.default_months_slider, window.default_mode, window.default_carencia);
        });
    }

    // evento para cuando el usuario cambia la carencia

    $("#form_carencia").change( function (event){
        window.default_carencia = parseInt($(this).val());
        calculaCostes (window.default_euros_slider, window.default_months_slider, window.default_mode, window.default_carencia);
    });

    // mostramos u ocultamos valores en funcion del filtro de producto

    $("#costePlanes").on("change", function() {
        filtrarPlanes();
    });

    // mostramos u ocultamos valores en funcion del filtro de seguro

    $("#opcionSeguro").on("change", function() {
        filtrarPlanes();
    });

    // definimos slider

    if (window.cantidadFija === 0) {
        window.slider_euros = $("#euros > div.slider > div.bar");
        window.slider_euros.slider({
            min: window.min_euros_slider,
            max: window.max_euros_slider,
            value: window.default_euros_slider,
            step: 0.01,
            range: 'min',
            animate: 'slow',
            slide: function( event, ui ) {
                filtrarPlanes();
                calculaCostes (ui.value,  window.default_months_slider, window.default_mode, window.default_carencia);
            },
            change: function (event, ui){
                filtrarPlanes();
                calculaCostes (ui.value,  window.default_months_slider, window.default_mode, window.default_carencia);
            }
        });


        $(function() {

            // Adaptamos los eventos de touch a click para que se pueda utilizar desde iPhone, iPad y Android
            document.getElementById("euros_slider").addEventListener("touchstart", touchHandler, { passive: false });
            document.getElementById("euros_slider").addEventListener("touchmove", touchHandler, { passive: false });
            document.getElementById("euros_slider").addEventListener("touchend", touchHandler, { passive: false });
            document.getElementById("euros_slider").addEventListener("touchcancel", touchHandler, { passive: false });

        });


    }


});
